<template>
  <article v-loading="loading" @click="articleClick">
    <RouterNav :name="name"></RouterNav>
    <header class="header">
      <div class="flex">
        <div class="left">
          <div class="name">{{name}}</div>
          <div :class="[statusName === '未开始' ? 'theme-color' : statusName === '进行中' ? 'col-1DC060' : 'col-999']">
            【{{statusName}}】
          </div>
        </div>
        <div class="right">
          <span>当前考试还有</span><span class="num">{{unfinishNum}}</span><span>人未完成</span><span class="check-btn" @click="checkUnfinish">查看</span>
        </div>
      </div>
    </header>
    <section class="container">
      <div class="menu">
        <div
            :class="['list', menuId === index ? 'active' : '']"
            v-for="(item, index) in menuList" :key="item.id"
            @click="menuChange(item.id)">
          <template v-if="item.id !== 0 || flag !== 4">
            <div>{{item.name}}</div>
            <div class="line"></div>
          </template>
        </div>

        <i v-if="iframeVisible" class="el-icon-full-screen" @click="openWindow"></i>
      </div>
      <Nav
          :classId="classId"
          :menuIndex="menuId"
          :groupId="groupId"
          :classInfoList="classInfoList"
          :examInfoList="examInfoList"
          :superAdmin="superAdmin"
          @classChange="classChange"
          @examChange="examChange"
          @exportExcel="exportExcel"
      />
      <div ref="content" class="content">
        <div v-show="menuId === 0">
          <ExamStatistics
            ref="ExamStatistics"
            :category="category"
            :examId="examId"
            :itemId="itemId"
            :classId="classId"
            :className="className"
            v-if="flag !== 4"
          />
        </div>
        <div v-show="menuId === 1" style="height: 100%;">
          <UserList
            :category="category"
            :paperGroupId="paperGroupId"
            :flag="flag"
            :list="userList"
            :page-index="pageIndex"
            :page-size="pageSize"
            :initQuestionTypes="initQuestionTypes"
            :sortType="sortType"
            :sortBy="sortBy"
            :statusName="statusName"
            :homeworkItemName="homeworkItemName"
            @sort="sort"
          />
          <el-pagination
            class="mt-20 mb-20 text-center"
            background
            layout="prev, pager, next, jumper"
            :current-page.sync="pageIndex"
            :page-size="pageSize"
            :total="pageTotal"
            @current-change="handleCurrentChange"
          />
        </div>
        <div v-show="menuId === 2">
          <iframe
              class="iframe"
              :src="iframeSrc"
              frameborder="0"
              v-if="flag !== 4"
          ></iframe>
          <div v-else>暂无数据...</div>
        </div>
      </div>
    </section>

    <Dialog
        :dialogType="dialogType"
        :dialogFlag="dialogFlag"
        :list="dialogList"
        :visible="dialogVisible"
        @handleClose="handleClose"
        @batchUrge="batchUrge"
        @batchRedo="batchRedo"
    ></Dialog>

    <UnfinishedDialog
        ref="UnfinishedDialog"
        :visible="unfinishedDialogVisible"
        :bagId="bagId"
        :examId="examId"
        :itemId="itemId"
        :name="name"
        :gradeName="gradeName"
        :dateEnd="dateEnd"
        :list="unfinishedStudentList"
        :statusName="statusName"
        @handleClose="unfinishedClose"
    ></UnfinishedDialog>
  </article>
</template>

<script>
  import RouterNav from '@/components/RouterNav.vue'
  import UserList from './components/UserList.vue'
  import Dialog from '../homework/check/components/Dialog.vue'
  import UnfinishedDialog from './components/UnfinishedDialog.vue'
  import ExamStatistics from './components/ExamStatistics.vue'
  import Nav from './components/Nav.vue'
  import {getUUID} from "@/utils/local";

  export default {
    name: 'examDetail',
    components: {
      UserList,
      Dialog,
      RouterNav,
      Nav,
      UnfinishedDialog,
      ExamStatistics
    },
    data() {
      return {
        loading: false,
        name: '',
        examId: '',
        itemId: '',
        category: 2,
        paperGroupId: '',
        gradeName: '',
        statusName: '',
        count: '',
        dateEnd: '',
        userList: [],
        initQuestionTypes: [],
        unfinishNum: 0,
        unfinishedStudentList: [],
        unfinishedDialogVisible: false,
        menuList: [{
          id: 0,
          name: '统计报告'
        }, {
          id: 1,
          name: '学生成绩'
        }, {
          id: 2,
          name: '答案/录音'
        }],
        menuId: 0,
        flag: 5,
        alphabetList: [],
        wordList: [],
        typeList: [],
        repeatAfterData: null,
        dialogVisible: false,
        dialogType: 2,
        dialogFlag: 1, // 1:批量催交，2：批量点评，3：批量打回
        dialogList: [],
        batchShow: false, // 批量操作状态控制
        sortType: 'desc',
        sortBy: 'activeRate',
        pageIndex: 1,
        pageSize: 30,
        pageTotal: 1,
        classInfoList: [],
        classId: '',
        className: '',
        examInfoList: [],
        groupId: '',
        paperClassId: '',
        paperExamId: '',
        iframeSrc: '',
        superAdmin: 0,
        homeworkItemName: '', // 子作业名称
      }
    },
    computed: {
      userInfo() {
        return this.$store.state.userInfo
      },
      iframeVisible() {
        return this.menuId === 2 && this.flag !== 4
      }
    },
    created() {
      this.superAdmin = sessionStorage.getItem('superAdmin')
      const {query} = this.$route
      this.bagId = query.bagId
      this.itemId = query.itemId
      this.examId = query.examId
      this.name = query.name
      this.classId = query.classId || ''
      this.className = query.className || ''
      this.statusName = query.statusName
      this.gradeName = query.gradeName
      this.unfinishNum = query.unfinishNum
      this.flag = Number(query.flag)
      if (this.flag === 4) {
        this.menuId = 1
      }
      window.addEventListener('message', this.onMessageCallback)
    },
    mounted() {
      if (this.name) {
        // this.getStudentScoreDetail()
        this.getExamConditionById()
        if (this.flag !== 4) {
          this.$refs.ExamStatistics.totalStatistics()
        }
      } else {
        this.getExamInfo()
      }
    },
    beforeDestroy() {
      window.removeEventListener('message', this.onMessageCallback)
    },
    methods: {
      onMessageCallback(event) {
        const data = event.data
        if (data.from !== 'xiyou-h5-teacher') return
        if (data.type === 'error') {
          const classId = this.classId || this.classInfoList[0].classId
          this.iframeSrc = `${this.$urls.iframeSrc}?groupId=${this.groupId}&classId=${classId}&examId=${this.examId || this.itemId}`
        }
      },
      getExamInfo() {
        this.$axios({
          method: 'post',
          url: this.$urls.getExamInfo,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            examId: this.examId
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            const {data} = response
            this.name = data.name
            this.classId = data.classId || ''
            this.statusName = data.statusName
            this.gradeName = data.gradeName
            this.flag = data.flag
            if (this.flag === 4) {
              this.menuId = 1
            } else {
              this.$refs.ExamStatistics.totalStatistics()
            }
            this.getStudentScoreDetail()
          }
        }).catch(() => {
          this.loading = false
        })
      },
      articleClick() {
        const {userList} = this
        for (let i = 0, len = userList.length; i < len; i += 1) {
          this.$set(this.userList[i], 'answerShow', false)
          this.$set(this.userList[i], 'popoverShow', false)
        }
      },
      handleCurrentChange() {
        this.getStudentScoreDetail()
      },
      batchRedo(userIds) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.batchRedo,
          data: {
            userIds,
            itemId: this.examId || this.itemId
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.dialogVisible = false
            this.$message({
              message: '批量打回成功',
              type: 'success'
            })
          }
        }).catch(() => {
          this.loading = false
        })
      },
      batchUrge(userIds) {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.batchUrge,
          data: {
            userIds
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.dialogVisible = false
            this.$message({
              message: '批量催交成功',
              type: 'success'
            })
          }
        }).catch(() => {
          this.loading = false
        })
      },
      batchDialog(flag) {
        if (this.status === '2') {
          this.$alert('已过期无法操作', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        this.dialogType = 3
        this.dialogFlag = flag
        const {userList} = this
        let arr = []
        if (flag === 3) {
          for (let i = 0, len = userList.length; i < len; i += 1) {
            if (userList[i].activeRate !== 0) {
              arr.push(userList[i])
            }
          }
          if (arr.length === 0) {
            this.$message('当前作业未有完成学生！')
            return
          }
        } else {
          arr = [...userList]
        }
        this.dialogList = arr
        this.dialogVisible = true
      },
      classChange(val) {
        if (val === '' && this.menuId === 2) {
          this.$message.error('查看答案/录音不支持全部班级')
          return
        }
        this.classId = val
        const {classInfoList} = this
        for (let i = 0, len = classInfoList.length; i < len; i += 1) {
          if (val === classInfoList[i].classId) {
            this.className = classInfoList[i].className
            this.itemId = classInfoList[i].itemId
            break
          }
        }
        if (this.groupId !== '') {
          const userId = this.userInfo.id
          const sessionId = sessionStorage.getItem('sessionId')
          const udid = getUUID()
          // this.iframeSrc = `${this.$urls.iframeSrc}?groupId=${this.groupId}&classId=${val}&examId=${this.examId || this.itemId}`
          this.iframeSrc = `${this.$urls.checkReportUrl}?reportType=2&groupId=${this.groupId}&classId=${this.classId}&itemId=${this.itemId}&userId=${userId}&udid=${udid}&authToken=${sessionId}&apps=2`
        }
        this.pageIndex = 1
        this.getStudentScoreDetail()
      },
      examChange(val) {
        if (this.menuId === 1 && val === '') return
        this.groupId = val
        if (val !== '') {
          const userId = this.userInfo.id
          const sessionId = sessionStorage.getItem('sessionId')
          const udid = getUUID()
          // this.iframeSrc = `${this.$urls.iframeSrc}?groupId=${val}&classId=${this.classId}&examId=${this.examId || this.itemId}`
          this.iframeSrc = `${this.$urls.checkReportUrl}?reportType=2&groupId=${this.groupId}&classId=${this.classId}&itemId=${this.itemId}&userId=${userId}&udid=${udid}&authToken=${sessionId}&apps=2`
        }
        this.pageIndex = 1
        this.getStudentScoreHeader(this.groupId)
        this.getStudentScoreDetail()
      },
      getStudentScoreHeader(groupId) {
        const dataParam = { groupId }
        this.$axios({
          method: 'post',
          url: this.$urls.getStudentScoreHeaderExam,
          headers: {
            'Content-Type': 'application/json'
          },
          data: dataParam
        }).then(res => {
          if (res.state !== '11') return
          this.initQuestionTypes = res.data
        })
      },
      exportExcel() {
        const that = this
        const h = this.$createElement
        let checked = false
        const random = Math.random()
        const superAdmin = sessionStorage.getItem('superAdmin')
        const schoolSection = sessionStorage.getItem('schoolSection')
        const localProvinceId = sessionStorage.getItem('schoolProvinceId')
        const isSuperTeacher = superAdmin === '2'
        const provinceId = isSuperTeacher ? String(this.$route.query.provinceId) : localProvinceId
        const section = isSuperTeacher ? String(this.$route.query.section) : schoolSection
        this.$confirm('确认导出成绩吗？', '提示', {
          message: h('div', null, [
            h('p', null, '确认导出成绩吗？'),
            (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
              style: 'position: absolute;left: 6px;bottom: -30px;',
              key: random,
              props: {
                checked: checked
              },
              on: {
                change(val) {
                  checked = val
                }
              }
            }, '导出转换为20分制') : ''),
          ]),
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const convertScore = checked ? 1 : 0
          checked = false
          const {classId, groupId} = this
          const dataParam = {
            userId: this.userInfo.id,
            clazzId: classId,
            groupId,
            convertScore
          }
          if (isSuperTeacher) {
            dataParam.id = this.examId
          } else {
            dataParam.itemId = this.itemId
          }
          this.$axios({
            method: 'post',
            url: this.$urls.exportStudentScoreDetailExam,
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataParam
          }).then((response) => {
            if (response.state === '11') {
              that.$message.success('下载任务已提交，请前往下载中心查看进度')
            }
          })

          // let url = `${that.$urls.exportStudentScoreDetail}?convertScore=${convertScore}`
          // if (this.examId) {
          //   url = `${url}&id=${that.examId}`
          // } else {
          //   url = `${url}&itemId=${that.itemId}`
          // }
          //
          // if (classId !== '') url = `${url}&classId=${classId}`
          // if (groupId !== '') url = `${url}&groupId=${groupId}`
          // // window.open(url)
          // const downloadElement = document.createElement('a')
          // downloadElement.href = url
          // document.body.appendChild(downloadElement)
          // downloadElement.click()
        }).catch((err) => {
          throw err
        })
      },
      handleClose() {
        this.dialogVisible = false
      },
      unfinishedClose() {
        this.unfinishedDialogVisible = false
      },
      checkUnfinish() {
        this.unfinishedDialogVisible = true
      },
      findUnfinishedStudentList() {
        this.loading = true
        const dataParam = {}
        const {examId} = this
        const {itemId} = this
        if (this.examId) {
          dataParam.id = examId
        } else {
          dataParam.itemId = itemId
        }
        this.$axios({
          method: 'post',
          url: this.$urls.findUnfinishedStudentList,
          headers: {
            'Content-Type': 'application/json'
          },
          data: dataParam
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.dateEnd = response.info.dateEnd
            const {unfinishedStudentList, name} = response.info
            this.homeworkItemName = name
            this.unfinishedStudentList = unfinishedStudentList
            this.unfinishNum = unfinishedStudentList.length
          }
        }).catch(() => {
          this.loading = false
        })
      },
      menuChange(id) {
        this.menuId = id
        const {userList} = this
        for (let i = 0, len = userList.length; i < len; i += 1) {
          userList[i].answerShow = false
        }
        if (id === 1 && userList.length === 0) {
          this.getStudentScoreDetail()
        }
        if (id === 2) {
          if (this.groupId === '') {
            this.groupId = this.examInfoList[0].groupId
          }
          const {flag} = this
          if (flag === 2 && this.alphabetList.length === 0) {
            this.getPaper()
          } else if (flag === 7 && this.wordList.length === 0) {
            this.getWordList()
          } else if (flag === 3 && !this.repeatAfterData) {
            this.getRepeatAfterData()
          } else if (this.iframeSrc === '') {
            const userId = this.userInfo.id
            const sessionId = sessionStorage.getItem('sessionId')
            const udid = getUUID()
            if (!this.classId) {
              this.classId = this.classInfoList[0].classId
              this.itemId = this.classInfoList[0].itemId
            }
            // this.iframeSrc = `${this.$urls.iframeSrc}?groupId=${this.groupId}&classId=${this.classId}&examId=${this.examId || this.itemId}`
            this.iframeSrc = `${this.$urls.checkReportUrl}?reportType=2&groupId=${this.groupId}&classId=${this.classId}&itemId=${this.itemId}&userId=${userId}&udid=${udid}&authToken=${sessionId}&apps=2`
          }
        }
      },
      openWindow() {
        if (!this.iframeVisible) return
        window.open(this.iframeSrc)
      },
      getRepeatAfterData() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.repeatDetail,
          data: {
            itemId: this.examId || this.itemId,
            flag: this.flag,
            id: this.paperGroupId
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.repeatAfterData = response.data
          }
        }).catch(() => {
          this.loading = false
        })
      },
      getWordList() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.homeworkWordList,
          data: {
            itemId: this.examId || this.itemId,
            flag: this.flag
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.wordList = response.data
          }
        }).catch(() => {
          this.loading = false
        })
      },
      getPaper() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.paperQuestions,
          data: {
            itemId: this.examId || this.itemId,
            flag: this.flag
          }
        }).then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.alphabetList = response.data
          }
        }).catch(() => {
          this.loading = false
        })
      },
      getStudentScoreDetail() {
        this.loading = true
        const {pageIndex} = this
        const {pageSize} = this
        const {classId} = this
        const {groupId} = this
        const dataParam = {}
        const {examId} = this
        const {itemId} = this
        if (examId) {
          dataParam.id = examId
        } else {
          dataParam.itemId = itemId
        }
        if (classId !== '') dataParam.clazzId = classId
        if (groupId !== '') dataParam.groupId = groupId
        dataParam.userId = this.userInfo.id
        dataParam.page = pageIndex
        dataParam.pageSize = pageSize
        dataParam.needQuestionType = true // 是否返回题型分数详情
        this.$axios({
          method: 'post',
          url: this.$urls.getStudentScoreDetail,
          headers: {
            'Content-Type': 'application/json'
          },
          data: dataParam
        }).then((response) => {
          // this.loading = false
          if (response.state === '11') {
            const {pageContents, total} = response.data
            this.pageTotal = total
            this.userList = pageContents
            this.getExamConditionById()
          }
        }).catch(() => {
          this.loading = false
        })
      },
      getExamConditionById() {
        const dataParam = {}
        const {examId} = this
        const {itemId} = this
        if (examId) {
          dataParam.id = examId
        } else {
          dataParam.itemId = itemId
        }
        this.$axios({
          method: 'post',
          url: this.$urls.getExamConditionById,
          headers: {
            'Content-Type': 'application/json'
          },
          data: dataParam
        }).then((response) => {
          if (response.state === '11') {
            this.findUnfinishedStudentList()
            const {info} = response
            this.classInfoList = info.classInfoList
            this.examInfoList = info.examInfoList
            if (this.groupId === '') {
              this.groupId = this.examInfoList[0].groupId
            }
            this.getStudentScoreHeader(this.groupId)
          }
        })
      },
      sort(name) { // 排序
        const {sortType} = this
        const {sortBy} = this
        if (sortBy !== name) {
          this.sortBy = name
          this.sortType = 'desc'
        } else {
          this.sortType = sortType === 'desc' ? 'asc' : 'desc'
        }
        const {userList} = this
        userList.sort((a, b) => {
          return this.sortType === 'asc' ? a[name] - b[name] : b[name] - a[name]
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    background-color: #fff;
    padding: 20px 28px;
    margin-bottom: 10px;

    .flex {
      @include flex(space-between);
      margin-bottom: 4px;

      .left {
        @include flex;
        font-size: 15px;

        .name {
          font-size: 20px;
          font-weight: bold;
          margin-right: 10px;
        }
      }

      .right {
        @include flex;

        .check-btn {
          display: inline-block;
          margin-left: 10px;
          color: #309AF2;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .excel-btn {
        .icon {
          @include icon(url("../../assets/excel.png"), 26px, 26px);
          margin-right: 6px;
        }
      }
    }

    .info {
      font-size: 15px;
      margin-bottom: 10px;

      .mr-28 {
        display: inline-block;
        margin-right: 28px;
      }
    }
  }

  .container {
    background-color: #fff;
    height: calc(100vh - 80px - 54px - 70px - 10px);

    .menu {
      @include flex(flex-start, flex-end);
      height: 54px;
      padding-bottom: 4px;
      border-bottom: 1px solid #E5E5E5;
      margin-left: 28px;
      font-size: 15px;
      position: relative;

      .list {
        margin-right: 28px;
        cursor: pointer;
        color: #999999;

        .line {
          background-color: transparent;
          width: 12px;
          height: 4px;
          margin: 7px auto 0;
          border-radius: 2px;
        }
      }

      .active {
        font-weight: bold;
        color: #333333;

        .line {
          background-color: #309AF2;
        }
      }

      .el-icon-full-screen {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-style: normal;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .content {
      height: calc(100% - 54px - 64px);
      overflow: auto;
    }

    .iframe {
      width: 100%;
      height: calc(100vh - 350px);
    }
  }

  .batch-list {
    height: 41px;
    line-height: 41px;
    @include font(15px, #333, center);
    cursor: pointer;

    &:hover {
      background-color: #F6F6F6;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  ::v-deep .user-list {
    height: calc(100% - 68px);
  }
</style>
